* {
  /* font-family: "Work Sans", sans-serif; */
  /* font-family: "Jost", sans-serif; */
  font-family: "Montserrat", sans-serif;
}
html {
  background: #f3f3f3;
  scroll-behavior: smooth;
}

.main-content {
  /* background: #fbfbfb; */
  background: #f5f6f7;
}

/* Page */
.page {
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-bottom: 50px;
}
.page-content {
  padding: 30px;
  padding-bottom: 55px;
}

/* Auth Page */
.ant-form-explain {
  text-align: left;
}
.auth-page {
  width: 100%;
}
.auth-page > .register-form__wrapper {
  width: 60%;
  margin: auto;
  height: calc(100vh - 100px);
  margin-top: 50px;
}

.auth-form form.login-form {
  margin-top: 30px;
  padding: 30px;
  background: white;
  min-width: 350px;
  text-align: center;
}
.auth-form form.login-form > h2 {
  margin-bottom: 20px;
}

/* Course - Curriculum Page */
.page.courses-curriculum-page .page-content {
  padding: 0px;
}

/* Auth Form */
.auth-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
.auth-form.create-school > p {
  width: 60%;
  text-align: center;
}
.auth-form button.register-button {
  width: 100%;
}
.auth-form .register-form {
  margin-top: 30px;
  width: 300px;
}
.auth-form.create-school .register-form {
  margin-top: 10px;
}
.auth-form p.switch-auth-link {
  margin: 10px 0px;
  text-align: center;
}
.auth-form p.switch-auth-link > button {
  padding: 0px;
}

.social-login-wrapper {
  position: relative;
  margin-top: 10px;
  height: 130px;
  border-top: 2px solid #f7f5f5;
  margin-top: 1em;
}
.social-login-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
}
.firebaseui-card-content {
  padding: 0px !important;
}
.firebaseui-list-item button {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: unset;
  box-shadow: unset !important;
}
.firebaseui-idp-google {
  background-color: #fff3ef !important;
}

/* Dashboard Sider */
.sider {
  width: 220px;
  height: 100vh;
  background: #35353c;
  position: absolute;
  left: 0px;
  top: 0px;
  position: fixed;
  overflow: hidden;
  z-index: 1;
}

.sider > .sider-title {
  height: 55px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
}
.sider > .sider-title > img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.sider > .sider-title ul.ant-skeleton-paragraph,
.sider > .sider-title .school-name {
  margin: 0px;
  margin-left: 15px;
}

.sider ul.sider-menu {
  margin: 0px;
  padding: 0px;
}
.sider ul.sider-menu__bottom {
  position: absolute;
  width: 100%;
  bottom: 50px;
}
.sider div.footer-profile {
  position: absolute;
  height: 40px;
  width: 100%;
  bottom: 0px;
  background-color: #252525;
}
.sider ul.sider-menu > li {
  padding: 12px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #b3b3b3;
  transition: all 0.5s;
}
.sider ul.sider-menu > li.item-selected {
  background: #00000033;
}

.sider ul.sider-menu > li:hover {
  cursor: pointer;
  background: #00000033;
}

.sider ul.sider-menu > li > div > span {
  margin-left: 12px;
  opacity: 1;
}

.sider ul.sider-menu > li.submenu {
  position: absolute;
  right: -180px;
  top: 55px;
  background: #2a2a30;
  width: 176px;
  height: 100%;
  padding: 0px;
  opacity: 0.5;
  transition: all 0.5s;
}

.sider ul.sider-menu > li.submenu.open-submenu {
  right: 0px;
  opacity: 1;
}

.sider ul.sider-menu > li.submenu > ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  width: inherit;
  height: inherit;
  padding-top: 10px;
}

.sider ul.sider-menu > li.submenu > ul > li {
  padding: 5px 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #b3b3b3;
  transition: all 0.5s;
}
.sider ul.sider-menu > li.submenu > ul > li > a {
  color: #b3b3b3;
}
.sider ul.sider-menu > li.submenu > ul > li:hover > a {
  color: white;
}

/* Layout */
.main-content {
  flex: 1;
  margin-left: 220px;
  min-height: 100vh;
}
.page-header {
  height: 55px;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 3px 4px #eaeaea;
  background: white;
}

.page-header.school-header {
  background: #171717;
  padding: 0px 20px;
}

.page-header.school-header > h1,
.page-header.school-header > h3 {
  color: white;
  font-size: 1.3em;
}
.page-header.school-header .ant-btn-link {
  color: white;
}

.page-header > .page-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.page-header h1,
.page-header h2,
.page-header h3 {
  margin: 0px;
}

.page-header .header-actions > button {
  margin-left: 15px;
}

.page-header.lecture-header {
  background: #2595da;
  padding: 0px;
}

.page-header.lecture-header > .left-buttons {
  width: 300px;
  height: inherit;
  padding: 0px 10px;
  background: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page-header.lecture-header > .right-buttons {
  height: inherit;
  padding: 0px 10px;
  width: calc(100% - 300px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-header.lecture-header > .right-buttons > .ant-btn {
  color: white;
  font-size: 1.2em;
}

.page-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 55px;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.page-footer p {
  margin: 0px;
}

/* Error Page 404 */
.error-404-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.filterbar {
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background: whitesmoke;
}

/* Alignment */
.align-right {
  text-align: right;
}

.content-loader {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-loader .anticon {
  font-size: 2em;
}
.content-loader h3 {
  margin-top: 10px;
}

/* New Course Page */
.new-course-page .form-wrapper,
.information-course-page .form-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}
.new-course-page .ant-form-item-required::before {
  content: "";
}
.new-course-page .form-info,
.information-course-page .form-info {
  flex: 1;
  color: #969696;
  padding-right: 20px;
}
.information-course-page .form-wrapper {
  padding-bottom: 30px;
  border-bottom: 1px solid #e4e4e4;
}

.form-card {
  flex: 2;
  padding: 40px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #efefef;
  border-bottom: 3px solid #00000014;
}

/* Courses Page */
.course-list {
  /* margin-top: 50px; */
}
.course-item-card {
  border: 1px solid #efefef;
  border-bottom: 3px solid #00000014;
  cursor: pointer;
  transition: all 0.7s;
  position: relative;
  background: white;
  margin: 10px;
}
.course-item-card:hover {
  box-shadow: 0px 3px 15px #00000014;
  border-width: 1px;
}
.course-item-card .course-image {
  text-align: center;
  font-size: 4em;
  padding: 30px;
  border-bottom: 1px solid #efefef;
}
.course-item-card .course-info {
  padding: 20px;
}
.course-item-card .course-info .course-title {
  font-size: 1.3em;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.course-item-card .course-info .course-states {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.school-detail-page > .page-content {
  padding: 0px;
  margin: 0px;
  height: 100%;
}

.school-detail-page > .page-content > .course-detail {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.course-detail-sider {
  width: 300px;
  height: 100%;
  background: #f7f7f7;
}

.course-detail-sider .course-image {
  height: 300px;
}
.course-detail-sider .course-image img {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  margin: 20px;
}
.course-detail-sider .course-name,
.course-detail-sider .course-progress {
  padding: 10px 20px;
}
.course-detail-sider .course-name > h3 {
  margin: 0px;
}
.course-detail-sider .course-progress .ant-progress {
  margin: 0px !important;
}

.course-detail-sider .menu-list button {
  width: 100%;
  text-align: left;
  padding: 10px 20px;
  height: 50px;
  font-weight: 500;
}

.course-detail-sider .menu-list button:hover,
.course-detail-sider .menu-list button.active {
  background: white;
  border-width: 0px;
}

.course-detail-main {
  flex: auto;
}

.course-detail-main > div {
  padding: 30px;
}

.course-detail-main .next-lecture-button-box {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  background: #f7f7f7;
  margin: 20px 0px;
}

.section-box > ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  margin-bottom: 30px;
}

.section-box > ul li.section-name,
.section-box > ul li.lecture {
  padding: 10px 20px;
  background: #f7f7f7;
  color: black;
  font-size: 1.2em;
  border-bottom: 2px solid white;
}
.section-box > ul li.lecture {
  display: flex;
  flex-direction: row;
  font-size: 1.1em;
}
.section-box > ul li.lecture:hover {
  background: #e6e6e6;
  /* cursor: pointer; */
}

.lecture-list-sider .section-box > ul li.lecture:hover {
  cursor: pointer;
}

.section-box > ul li.lecture .lecture-progress {
  padding-right: 20px;
}

.section-box > ul li.lecture .lecture-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.section-box > ul li.lecture button {
  margin-left: 10px;
}

.lecture-list-sider {
  width: 300px;
  min-height: 100vh;
  position: absolute;
  left: 0px;
  top: 55px;
  padding: 15px;
}

.lecture-list-sider > .header,
.school-lecture-page .lecture-view > .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0px 10px;
  background: black;
}

.lecture-video-view > .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.lecture-video-view > .header > .anticon {
  font-size: 1.3em;
}

.lecture-video-view > .header > h2 {
  margin: 0px;
  margin-left: 15px;
}

.school-lecture-page .lecture-view > .header {
  background: #2595da;
  justify-content: center;
  color: white;
  font-size: 1.2em;
}
.school-lecture-page .lecture-view > .header > div {
  cursor: pointer;
}
.school-lecture-page .lecture-view > .header > .anticon {
  margin-left: 10px;
}

.lecture-list-sider > .course-detail-sider {
  padding: 10px 0px;
  background: white;
}

.school-lecture-page .lecture-view {
  margin-left: 300px;
  min-height: 100vh;
}
.school-lecture-page .lecture-view > .lecture-content {
  padding: 30px;
}

/* Curriculum */

.section-droppable-box {
  /* padding: 20px 40px; */
  transition: all 0.3s;
}
.section-draggable {
  padding: 20px 0px;
}
.course-section__wrapper {
  background: #fff;
  border-radius: 3px;
  border: 1px solid #efefef;
  border-bottom: 3px solid #00000014;
}
.course-section,
.course-lecture {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  height: 50px;
}
.course-section {
  border-bottom: 1px solid #e6e6e6;
}
.course-lecture {
  background: #f5f5f5;
  border-bottom: 1px solid #e6e6e6;
}
.course-section__left,
.course-lecture__left {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  flex: 1;
}
.course-section__left > .anticon,
.course-lecture__left > .anticon {
  cursor: move;
  margin-right: 10px;
  font-size: 1.2em;
}
.course-section__left > label,
.course-lecture__left > label {
  margin: 0px 20px;
}
.course-section__left h3,
.course-lecture__left h3 {
  margin: 0px 10px 0px 20px;
}
.inline_edit__wrapper {
  flex: 1;
}
.inline_edit__wrapper > div.normal-mode,
.inline_edit__wrapper > div.edit-mode {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.inline_edit__wrapper > div.normal-mode > .anticon {
  cursor: pointer;
  color: gray;
}
.inline_edit__wrapper > div.normal-mode > .ant-tag {
  margin-left: 15px;
}
.inline_edit__wrapper > div.edit-mode > input {
  width: 60%;
}
.inline_edit__wrapper > div.edit-mode > input,
.inline_edit__wrapper > div.edit-mode > button {
  margin-right: 10px;
}

.inline_edit__wrapper > div.edit-mode div.edit-mode-inside {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.inline_edit__wrapper > div.edit-mode div.edit-mode-inside div.ant-form-item {
  margin-bottom: 0px;
}

.inline_edit__wrapper > div.edit-mode div.edit-mode-inside div.ant-form-item-control-wrapper {
  margin-right: 10px;
}

.inline_edit__wrapper > div.edit-mode div.edit-mode-inside button.ant-btn-circle {
  margin-right: 10px;
}

.course-lecture__right > .anticon {
  margin-right: 15px;
  font-size: 1.2em;
  color: gray;
}
.course-lecture.course-lecture-dragging {
  background: #efefef;
}

.new-lecture.tabs-wrapper,
.new-lecture.assets-wrapper {
  margin: 20px;
}

.new-lecture.tabs-wrapper > .ant-tabs-card > .ant-tabs-content {
  min-height: 120px;
  margin-top: -16px;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #efefef;
  border-bottom: 3px solid #00000014;
  border-top-width: 0px;
}

.new-lecture.tabs-wrapper > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 0px;
}

.new-lecture.tabs-wrapper > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.new-lecture.tabs-wrapper > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.new-lecture.tabs-wrapper > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  background: #fff;
  border-color: #efefef;
  border-width: 1px;
  border-bottom-width: 0px;
}
#code-editor * {
  font-family: monospace !important;
  font-size: 16px !important;
  direction: ltr !important;
  text-align: left !important;
}
.new-lecture .braft-editor-main {
  border: 1px solid #f0f0f0;
}
.braft-editor-main .bf-content {
  height: auto !important;
}
.new-lecture .save-button {
  padding: 10px 0px;
  text-align: center;
}
.new-lecture .save-button > button {
  width: 100%;
}

.new-lecture div.code-editor__toolbar {
  width: 100%;
  height: 45px;
  background: #2f3129;
  border-radius: 5px 5px 0px 0px;
  padding: 0px 10px 0px 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.new-lecture div.code-editor__toolbar .ant-select-selection {
  background: #272822;
  border-width: 0px;
  color: white;
  min-width: 100px;
  outline: none;
  box-shadow: 0px 0px 0px 0px;
}
.new-lecture div.code-editor__toolbar .ant-select-arrow {
  color: white;
}

.new-lecture div.code-editor__toolbar .ant-select-selection:hover,
.new-lecture div.code-editor__toolbar .ant-select-open .ant-select-selection,
.new-lecture div.code-editor__toolbar .ant-select-focused .ant-select-selection,
.new-lecture div.code-editor__toolbar .ant-select-selection:focus,
.new-lecture div.code-editor__toolbar .ant-select-selection:active {
  border: 0px;
  background: #202020;
}

.new-lecture #code-editor {
  width: 100% !important;
  max-height: 300px;
  border-radius: 0px 0px 5px 5px;
}

.new-lecture.allow-comments,
.new-lecture.assets-wrapper .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #efefef;
  border-bottom: 3px solid #00000014;
  margin: 50px 20px 20px 20px;
  padding: 15px;
}
.new-lecture.allow-comments > h3,
.new-lecture.assets-wrapper .title > .asset-header h3,
.new-lecture.assets-wrapper .title {
  margin: 0px;
}
.asset-header {
  margin-bottom: 5px;
}
.asset-header > h3 {
  margin: 0px;
}
.new-lecture .asset-droppable-box > div {
  padding: 0px !important;
}
.new-lecture .asset-droppable-box .asset-draggable > div {
  padding: 0px !important;
}
.new-lecture .asset-draggable {
  margin-bottom: 20px;
}

.new-lecture .asset-draggable .asset-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
}

/* Lecture Show Page */
.lecture-show.attached-files {
  margin: 20px;
}
.lecture-show.attached-files .attached-files_image {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #efefef;
  border-bottom: 3px solid #00000014;
  padding: 10px;
}

/* Information */
.form-card.information-preview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.form-card.information-preview > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background: #f5f6f7;
  margin-right: 40px;
  box-shadow: 0px 2px 2px #00000014;
  cursor: pointer;
}
.form-card.information-preview > div:hover {
  box-shadow: 0px 4px 6px #00000014;
}
.form-card.information-preview > div > .anticon {
  font-size: 4em;
  margin-bottom: 20px;
}
.branding-thumbnail__wrapper {
  margin-bottom: 30px;
}

/* Pricing */
.new-pricing__wrapper,
.active-plan__wrapper {
  margin: 40px;
  background: white;
  border-radius: 2px;
  box-shadow: 0px 1px 4px #0000000d;
  padding-bottom: 30px;
}
.active-plan__wrapper {
  padding: 20px;
}
.new-pricing__title {
  padding: 10px 20px;
  background: #2a2a30;
  color: white;
  margin-bottom: 40px;
}
.new-pricing__wrapper .plan-item {
  text-align: center;
  width: 100%;
  padding: 30px 0px;
  cursor: pointer;
}
.new-pricing__wrapper .plan-item > img {
  height: 90px;
  margin-bottom: 20px;
  border-radius: 50%;
}
.pricing-form {
  max-width: 500px;
  margin: 30px auto;
}
.pricing-form > .title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.pricing-form > .title > h2 {
  margin: 0px;
  margin-left: 15px;
}

/* Comments */
.page.course-comments-page > div.comments-wrapper {
  padding: 40px;
}

.form-card.comment-box {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  min-height: 500px;
}

.form-card.comment-box > .anticon {
  font-size: 8em;
  margin-bottom: 30px;
}
.form-card.comment-box > h1,
.form-card.comment-box > p {
  max-width: 80%;
  margin: 0px auto;
  text-align: center;
}

/* Bundle */
.page.course-bundle-page > div.bundle-wrapper {
  padding: 40px;
}

.form-card.bundle-box {
  margin: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  min-height: 500px;
}

.form-card.bundle-box > .anticon {
  font-size: 8em;
  margin-bottom: 30px;
}
.form-card.bundle-box > h1,
.form-card.bundle-box > p {
  max-width: 80%;
  margin: 0px auto;
  text-align: center;
}

/* School Home Page */
.topbar-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.topbar-filter > div.left-items {
  display: flex;
}

.topbar-filter > div.left-items > div {
  display: flex;
  margin-right: 15px;
  align-items: center;
  background: wheat;
  padding: 5px 10px;
  border-radius: 10px;
}

.topbar-filter > div.left-items > div > div.ant-select {
  margin-left: 10px;
}

.topbar-filter > input {
  width: 300px;
}

.top-menu {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
  color: white;
  align-items: center;
}

.top-menu a {
  color: #ffffff80;
  transition: all 0.5s;
}

.top-menu a:hover {
  color: white;
}

.school-courses-page .filter-dropdown {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0px;
}
.school-courses-page .filter-dropdown > div {
  margin-right: 20px;
}
.school-courses-page .filter-dropdown > div .ant-select {
  width: 100px;
}

.school-courses-page .filter-input {
  float: right;
}

/* Mobile View */

/* Header */
.mobile-view .page-header.school-header {
  padding: 0px 5px 0px 20px;
}
.mobile-view .top-menu {
  display: none;
  position: absolute;
  left: 0px;
  top: 55px;
  width: 100%;
  /* display: flex; */
  flex-direction: column;
  background: black;
  z-index: 999;
}
.mobile-view .top-menu > li {
  width: 100%;
  padding: 5px;
}
.mobile-view .top-menu.open {
  display: flex;
}
.mobile-view .top-menu > li > ul {
  background: black;
  padding-left: 20px;
}

.mobile-view .top-menu > li > ul.open {
  display: block;
}

/* Page Content */
.mobile-view .page-content {
  padding: 20px;
}

.mobile-view .school-courses-page .filter-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.mobile-view .school-courses-page .filter-input {
  width: 100% !important;
}
.mobile-view .school-courses-page .filter-dropdown > div {
  flex: 1;
}
.mobile-view .school-courses-page .filter-dropdown > div:first-child {
  margin-right: 10px;
}

.mobile-view .school-courses-page .filter-dropdown > div .ant-select {
  width: 100%;
}

.mobile-view .page-header.lecture-header > .left-buttons {
  width: auto;
}

.mobile-view .page-header.lecture-header > .left-buttons .ant-btn {
  margin-right: 5px;
}

.mobile-view .page-header.lecture-header > .right-buttons {
  flex: auto;
}

/* Footer */
.mobile-view .page-footer {
  font-size: 1em;
}

/* Page sider */
.mobile-view #page-sider {
  position: absolute;
  top: 55px;
  width: 100%;
  left: -100vw;
  z-index: 90;
  transition: all 0.5s;
  background: white;
  padding: 15px;
}

.mobile-view #page-sider.open {
  left: 0px;
}

/* Page: Page Details */
.mobile-view .course-detail-main > div {
  padding: 0px;
}

/* Page Lecture */
.mobile-view .school-lecture-page .lecture-view {
  margin: 0px;
}

.mobile-view .school-lecture-page .lecture-view .lecture-content {
  padding: 20px;
}
